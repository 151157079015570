<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="用户姓名">
				<el-input class="el_input" v-model="form.name" placeholder="用户姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户手机">
				<el-input class="el_input" v-model="form.tel" placeholder="用户手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="推荐人">
				<el-input class="el_input" v-model="form.agent_tel" placeholder="推荐人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="身份证号">
				<el-input class="el_input" v-model="form.id_card_num" placeholder="推荐人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="实名认证">
				<el-select class="el_input" v-model="form.real_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未认证" value="1"></el-option>
					<el-option label="已通过" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户性别">
				<el-select class="el_input" v-model="form.sex" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="男" value="1"></el-option>
					<el-option label="女" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="创建时间">
				<div class="block">
				<el-date-picker
					v-model="build_date"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item"></el-form-item>
			<el-form-item label-width="0" id="button">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{ list.total }}条</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column width="100" label="ID" prop="id"></el-table-column>
				<el-table-column label="用户编号" prop="user_num"></el-table-column>
				<el-table-column label="注册时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="电话号码" prop="tel"></el-table-column>
				<el-table-column label="真实姓名" prop="name"></el-table-column>
				<el-table-column label="实名状态" prop="real_status_text"></el-table-column>
				<el-table-column label="账户状态" prop="status_text"></el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
				<!-- 操作行 -->
				<el-table-column label="操作" width="190">
					<template slot-scope="scope">
						
						<!-- 停用/启用 -->
						<el-button v-if="scope.row.status==1" @click="user_status_edit(scope.row.id,2)" size="mini" type="text">停用</el-button>
						<el-button v-if="scope.row.status==2" @click="user_status_edit(scope.row.id,1)" size="mini" type="text">启用</el-button>
						<el-button v-if="scope.row.pay_status==1" @click="user_payments_edit(scope.row.id,2)" size="mini" type="text">关闭支付</el-button>
						<el-button v-if="scope.row.pay_status==2" @click="user_payments_edit(scope.row.id,1)" size="mini" type="text">开启支付</el-button>
						<!-- 其他 -->
						
						<el-button @click="user_info_view_open(scope.row)" size="mini" type="text">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 用户详情 -->
		<userInfo 
			:user_info="user_info.data" 
			:is_show="user_info.is_show" 
			@list_refresh="get_page_data"
			@edit_success ="get_page_data"
		></userInfo>

		<!-- 用户添加 -->
		<userAdd 
			:is_show="user_add.is_show" 
			@list_refresh="get_page_data" 
		></userAdd>

	</div>
</template>

<script>
	import userInfo from './info.vue'
	import userAdd from './add.vue'
	export default {
		components:{
			userInfo,
			userAdd,
		},
		data() {
			return {

				//搜索条件
				form: {				
					name:'',//姓名
					tel:'',//电话
					agent_tel:'',//是否可用
					id_card_num:'',//身份证号
					real_status:'2',//实名状态(1:未审核,2:审核通过,3:审核失败)
					status:'',//状态
					sex:'',//性别
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				build_date:'',//创建时间
				creat_time_start:'', //开始时间
				creat_time_end:'', //结束时间
				//时间快捷操作
			    pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//用户详情
				user_info:{
					data:{},
					is_show:0,
				},

				//用户添加
				user_add:{
					is_show:0,
				}
			}
		},
		created() {
			
			//获取可用搜索参数
			this.get_page_data()
		},
		methods: {
			//支付开启/关闭
			user_payments_edit(uid,new_status){
				//提交
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						id:uid,
						pay_status:new_status,
					},
					callback:(data)=>{
						if(new_status ==1){
							this.$my.other.msg({
								type:'success',
								str:'支付功能已开启'
							});
						}else{
							this.$my.other.msg({
								type:'success',
								str:'支付功能已关闭'
							});
						}
						

						//刷新
						this.get_page_data()
					},
				});
			},
			//修改用户状态
			user_status_edit(uid,new_status){

				//提交
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						id:uid,
						status:new_status
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//刷新
						this.get_page_data()
					},
				});
			},

			//打开用户详情
			user_info_view_open(item){
				
				//置入数据
				this.user_info.data=item

				//打开弹出层
				this.user_info.is_show++
			},
			//关闭用户详情
			user_info_view_close(item){

				//关闭弹出层
				this.user_info.is_show=0;
				
				//清空数据
				this.user_info.data={}
			},

			//前往添加页面
			open_add_view(){
				this.user_add.is_show++
			},

			//前往修改页面
			open_edit_view(item){
				this.get_menu_list()
				this.edit_para.user_info=item
				this.edit_para.is_show=true
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					name:'',//姓名
					tel:'',//电话
					agent_tel:'',//是否可用
					id_card_num:'',//身份证号
					real_status:'2',//实名状态(1:未审核,2:审核通过,3:审核失败)
					status:'',//状态
					sex:'',//性别
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				if (this.build_date) {
					this.form.creat_time_start = parseInt(this.build_date[0] / 1000);
					this.form.creat_time_end = parseInt(this.build_date[1] / 1000);
					this.page.p = 1;
					this.get_page_data();
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
					this.page.p = 1;
					this.get_page_data();
				}
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取后台用户
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){
							
							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//实名状态
							switch(item.real_status){
								case '1':item.real_status_text='未实名';break;
								case '2':item.real_status_text='已实名';break;
							}

							//账户状态
							switch(item.status){
								case '1':item.status_text='正常';break;
								case '2':item.status_text='停用';break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 300px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.total_info {
	text-align: right;
	font-size: 12px;
	margin-bottom: 24px;
	margin-right: 1px;
	margin-top: 10px;
	color: #606266;
		span {
			margin-left: 20px;
		}
	}
	.item {
	font-weight: 800;
	}
	#button{
		margin-left: -120px;
	}
</style>