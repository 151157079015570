<template>
	<el-dialog top="1vh" title="用户详情" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit" style="margin-top:0px;">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户手机" style="width:320px">
					<el-input class="el_inner_width" v-model="user_info.tel"></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户姓名" style="width:320px">
					<el-input class="el_inner_width" v-model="user_info.name"></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="身份证号" style="width:320px">
					<el-input class="el_inner_width" v-model="user_info.id_card_num"></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户地址" style="width:660px">
					<el-input class="el_inner_width" v-model="addr" clearable>
						<el-button @click="id_card_ocr" slot="append">识别</el-button>
						<el-button @click="addr_sava" slot="append">保存</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="备注" style="width:660px">
					<el-input class="el_inner_width" v-model="mark" clearable>
					</el-input>
				</el-form-item>
			</div>

			<div class="big_tit">头像/身份证</div>
			<div class="tab1_inner">
				<div class="el_form_item" label="" style="width:32%">
					<div class="img_btn">
						<div v-if="!headimg_src" class="btn">未上传</div>
						<img  v-else :src="headimg_src" class="img" @click="show_img(headimg_src)">
					</div>
					<div class="bottom_text">
						头像 {{headimg_status_text}}
						<el-button @click="img_pass('headimg')" size="mini" type="text" style="margin-left:15px">通过</el-button>
						<el-button @click="img_unpass('headimg')" size="mini" type="text" style="color:red;">驳回</el-button>
						<el-button @click="voucher_add('headimg_src')" size="mini" type="text" style="margin-left:15px">重新上传</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!id_card_img1_src" class="btn">未上传</div>
						<img  v-else :src="id_card_img1_src" class="img" @click="show_img(id_card_img1_src)">
					</div>
					<div class="bottom_text">
						身份证正面 {{id_card_img1_status_text}}
						<el-button @click="img_pass('id_card_img1')" size="mini" type="text" style="margin-left:15px">通过</el-button>
						<el-button @click="img_unpass('id_card_img1')" size="mini" type="text" style="color:red;">驳回</el-button>
						<el-button @click="voucher_add('id_card_img1_src')" size="mini" type="text" style="margin-left:15px">重新上传</el-button>
					</div>
				</div>
				<div class="el_form_item" style="width:32%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!id_card_img2_src" class="btn">未上传</div>
						<img  v-else :src="id_card_img2_src" class="img" @click="show_img(id_card_img2_src)">
					</div>
					<div class="bottom_text">
						身份证反面 {{id_card_img2_status_text}}
						<el-button @click="img_pass('id_card_img2')" size="mini" type="text" style="margin-left:15px">通过</el-button>
						<el-button @click="img_unpass('id_card_img2')" size="mini" type="text" style="color:red;">驳回</el-button>
						<el-button @click="voucher_add('id_card_img2_src')" size="mini" type="text" style="margin-left:15px">重新上传</el-button>
					</div>
				</div>
			</div>
			<div class="btn">
               <el-button  type="success" @click="user_info_edit">提交编辑</el-button>
			</div>
		</el-form>

		<el-dialog
			top="2vh"
			width="90%"
			title="查看大图"
			:modal="false"
			:visible.sync="img.is_show"
		>
			<img :src="img.src" style="width:100%">
		</el-dialog>

		 <!-- 凭证添加界面 -->
		<el-dialog
			title="证件上传"
			top="2vh"
			width="500px"
			:visible.sync="upl.is_show"
			:modal="false"
			>
			<div class="upl">
				<div class="img_area" @click="voucher_add_sub">
				<img v-if="upl.src" :src="upl.src" class="show" />
				<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>

				<div class="btn_area">
				<el-button type="primary" @click="voucher_sub()">上传</el-button>
				</div>
			</div>
		</el-dialog>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			user_info:Object,//用户数据
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//用户地址
				addr:'',

				mark:'',//备注
				//头像状态
				headimg_status_text:'',

				//头像
				headimg_src:'',

				headimg:'',
				//身份证正面状态
				id_card_img1_status_text:'',

				//身份证正面
				id_card_img1_src:'',
				id_card_img1:'',
				//身份证反面状态
				id_card_img2_status_text:'',

				//身份证反面
				id_card_img2_src:'',
				id_card_img2:'',
				//上传参数
				upl:{
					type:'', //上传得类型
					key:'', 
					is_show:false,
					src:'',
				},
				//大图
				img:{
					is_show:false,
					src:''
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.page_init();
					this.is_show_in_page=true;
				}
			},
		},
		methods: {
			voucher_add_sub(){
				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
					//预览
					this.upl.src = res.src;
					
					},
				});
			},
			voucher_sub(){
				this[this.upl.type] = this.upl.src
				//上传
				this.$my.qiniu.upl({
					bucket: "driversourceproof",
					file_path: this.upl.src,
					callback: (data) => {
					 var key = data.key.split('__v__1')
					 if(this.upl.type == 'id_card_img2_src'){
							this.id_card_img2 =  key[0]
						}else if(this.upl.type == 'id_card_img1_src'){
							this.id_card_img1 =  key[0]
						}else{
							this.headimg =  key[0]
						}
					},
				});
				this.upl.is_show = false;
			},
			//打开上传弹窗
			voucher_add(type){
				//打开上传界面
				this.upl.is_show = true;

				//清空上次上传的图片
				this.upl.src = "";

				this.upl.type = type
			},
			//编辑
			user_info_edit(){
				if(!this.$my.check.is_tel(this.user_info.tel)){
					this.$my.other.msg({
						type:'info',
						str:'手机号格式不正确'
					});
					return
				}
				if(!this.user_info.name){
					this.$my.other.msg({
						type:'info',
						str:'用户姓名不得未空'
					});
					return
				}
				if(!this.user_info.id_card_num){
					this.$my.other.msg({
						type:'info',
						str:'用户身份证号不得未空'
					});
					return
				}
								

				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						user_num:this.user_info.user_num,
						tel:this.user_info.tel,
						id_card_num:this.user_info.id_card_num,
						name:this.user_info.name,
						is_real_check : 1 ,
						headimg:this.headimg,
						id_card_img1:this.id_card_img1,
						id_card_img2:this.id_card_img2,
						mark:this.mark
					},callback:(data)=>{
					    this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.$emit('edit_success')
						this.is_show_in_page = false
					}
				})
			},
			//身份证识别
			id_card_ocr(){
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'img_ocr',
						img_type:'id_card',
						img_url:this.id_card_img1_src
					},
					callback:(data)=>{
						this.addr=data.address
					}
				});
			},

			//保存地址
			addr_sava(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_edit_by_admin',
						id:this.user_info.id,
						addr:this.addr
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						//通知
						this.$emit('list_refresh')
					}
				});
			},

			//图片不通过
			img_unpass(img){

				let form={
					mod:'app_user',
					ctr:'app_user_edit_by_admin',
					id:this.user_info.id,
				}

				switch(img){
					case 'headimg':
						var confirm_content="用户头像";
						form.headimg_status=4;
						break;
					case 'id_card_img1':
						var confirm_content="身份证正面";
						form.id_card_img1_status=4;
						break;
					case 'id_card_img2':
						var confirm_content="身份证反面";
						form.id_card_img2_status=4;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:"确认驳回'"+confirm_content+"'?",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:form,
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});

								//
								switch(img){
									case 'headimg':this.headimg_status_text="审核未通过";break;
									case 'id_card_img1':this.id_card_img1_status_text="审核未通过";break;
									case 'id_card_img2':this.id_card_img2_status_text="审核未通过";break;
								}

								//通知
								this.$emit('list_refresh')
							},
						});
					}
				});
			},

			//图片通过
			img_pass(img){

				let form={
					mod:'app_user',
					ctr:'app_user_edit_by_admin',
					id:this.user_info.id,
				}

				switch(img){
					case 'headimg':
						var confirm_content="用户头像";
						form.headimg_status=3;
						break;
					case 'id_card_img1':
						var confirm_content="身份证正面";
						form.id_card_img1_status=3;
						break;
					case 'id_card_img2':
						var confirm_content="身份证反面";
						form.id_card_img2_status=3;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:"确认通过'"+confirm_content+"'?",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:form,
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});

								//
								switch(img){
									case 'headimg':this.headimg_status_text="审核通过";break;
									case 'id_card_img1':this.id_card_img1_status_text="审核通过";break;
									case 'id_card_img2':this.id_card_img2_status_text="审核通过";break;
								}

								//通知
								this.$emit('list_refresh')
							},
						});
					}
				});
			},

			//页面初始化
			page_init(){

				let user_info=this.user_info

				//用户地址
				this.addr=user_info.addr

				//备注
				this.mark=user_info.mark
				//大图部分
				this.img.is_show=false
				this.img.src=''

				//头像状态
				switch(user_info.headimg_status){
					case '1':this.headimg_status_text='未上传';break;
					case '2':this.headimg_status_text='已上传审核中';break;
					case '3':this.headimg_status_text='审核通过';break;
					case '4':this.headimg_status_text='审核未通过';break;
				}

				//头像
				this.headimg_src=this.$my.qiniu.make_src('headimg',user_info.headimg)

				//身份证正面状态
				switch(user_info.id_card_img1_status){
					case '1':this.id_card_img1_status_text='未上传';break;
					case '2':this.id_card_img1_status_text='已上传审核中';break;
					case '3':this.id_card_img1_status_text='审核通过';break;
					case '4':this.id_card_img1_status_text='审核未通过';break;
				}

				//身份证正面
				this.id_card_img1_src=this.$my.qiniu.make_src('idcard1',user_info.id_card_img1)

				//身份证反面状态
				switch(user_info.id_card_img2_status){
					case '1':this.id_card_img2_status_text='未上传';break;
					case '2':this.id_card_img2_status_text='已上传审核中';break;
					case '3':this.id_card_img2_status_text='审核通过';break;
					case '4':this.id_card_img2_status_text='审核未通过';break;
				}

				//身份证反面
				this.id_card_img2_src=this.$my.qiniu.make_src('idcard2',user_info.id_card_img2)
			},

			//显示大图
			show_img(src){
				this.img.src=src
				this.img.is_show=true
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
		.img_btn{
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height:200px;
			.btn{
				text-align: center;
				line-height: 200px;
				font-size: 14px;
			}

			.img{
				display: block;
				margin:auto;
				width: 100%;
				height: 200px;
			}
		}
		.bottom_text{
			margin-top: 5px;
			text-align: center;
		}
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}
	.btn{
		margin-top: 20px;
		text-align: center;
	}
	.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}
</style>